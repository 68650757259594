.fnfSection {
  min-height: 100vh;
  padding: 2.8rem 1rem;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fnfCol {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start; /* Align to the start of the cross axis */
  gap: 1rem; /* Provides space between items */
}


.itemBox {
  width: 14rem;
  height:30%; /* Height will adjust to content */
  background-color: #f8f8f8; /* Light grey background */
  border: 1px solid #e0e0e0; /* Light grey border */
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Ensures content does not spill out */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Soft shadow for depth */
}
.fnfitemPrice{
  text-align: center;
  background-color: orange;
}

.itemImgFnf {
  width: 100%;
  min-height: 20rem; /* Fixed height for images */
  overflow: hidden; /* Ensures no part of the image spills out */

  display: flex;
  justify-content: center;
  align-items: center;
}
.imgContainer{
  /* border: 2px solid green; */
  width: 100%;
  background-color: orange;
}

.imgTag {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the container area, may clip the image */
  display: block;
}

.itemTitle {
  color: #333;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem 0; /* Spacing above and below the title */
  background-color: transparent;
  margin: 0;
}

.itemDes {
  text-align: center;

  padding: 0.5rem;
  font-size: 0.9rem;
  color: #666;
  background-color: transparent;
  height: auto; 
  overflow: hidden; 
}

.buyButton {
  padding: 0.5rem 1rem; 
  color: white; 
  cursor: pointer; 
  background-color: #0046d5; 
  border: none; 
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s;

  height: 2.8rem;
}
.soldOut {
  text-align: center;
  background-color: grey !important;
  color: white;
  cursor: default;
}

.buyButton:hover {
  background-color: #0036b5; /* Darker shade of blue on hover for visual feedback */
}

.alreadyAdded {
  display: flex; /* Uses flexbox for centering */
  justify-content: center; /* Centers horizontally in the flex container */
  align-items: center; /* Centers vertically in the flex container */
  width: 100%; /* Takes up full width of its parent */
  background-color: #bc7710; /* A warm, inviting color indicating success */
  padding: 0.5rem; /* Adds some space inside the container */
  color: white; /* Text color that contrasts with the background */
  border-radius: 4px; /* Adds rounded corners */
}

.alreadyAdded p {
  margin: 0; /* Removes default margin from the paragraph for consistent spacing */
  font-weight: bold; /* Makes the text bold */
}
.itemPrice {
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem 0;
  color: #333;
  background-color: transparent;
}

.itemAvailability {
  text-align: center;
  font-size: 0.9rem;
  padding: 0.2rem 0;
  color: #4caf50; /* Green for available, red for not */
}

/* Adapt the item boxes for smaller screens */
@media only screen and (max-width: 600px) {
  .fnfSection {
    padding: 1rem;
    width: 100%;
  }

  .fnfCol {
    justify-content: center;
  }

  .itemBox {
    width: 100%; /* Full width on small screens */
    margin: 0.5rem 0; /* Stack with space in between */
  }
}
