* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*
  SidePanel
*/
.sidePanel {
  z-index: 21;
  position: fixed;
  top: 0;
  right: 0;
  width: 25vw;
  height: 100vh;

  background-color: white;

  transform: translateX(100%);
  transition-duration: 750ms;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.sidePanelActive {
  transform: translateX(0%);
}
.cartTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 1rem 1rem;
  background-color: black;
  color: white;
}
.cartTitle > :nth-child(1) {
  position: relative;
  top: 50%;
  left: -25%;
}
.cartTitle > h2 {
  font-size: 2.1vw;
}
.closeSidePanelBtn {
  cursor: pointer;
}
.cartItemsContainer {
  width: 100%;

  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.itemDisplay {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  width: 100%;
  height: 100%;

  padding: 2rem;
  border-bottom: 1px solid rgb(185, 185, 185);
}
.itemImg {
  width: 40%;
  max-height: 50%;
}
.itemImg > img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.itemInfo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  height: 100%;
  padding: 1rem;
  /* background-color: red; */
}
.itemInfo > h2 {
  font-size: 1.4vw;
}
.productName {
  color: grey;
  text-transform: uppercase;
}
.productPrice {
  font-weight: bold;
}
.productSize {
  font-family: "Roboto", sans-serif;

  font-weight: 300;
  font-size: 0.8vw;
}
.selectQuantity {
  overflow: hidden;
  width: 100%;
  height: 1.7rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  border: 1px solid black;
}

.selectBtns {
  width: 2.5rem;
  /* flex-grow: 1; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.selectBtns.removeFnfBtn{
  width: 8rem;
  padding: 0.2rem 1.2rem;
  color: black;
}
.selectBtns > h2 {
  font-size: 1.3vw;
}
.selectBtns:hover {
  background-color: gray;
}
.selectBtns:focus {
  outline: none;
}
.selectQuantity .selectBtns:nth-child(2):hover {
  background-color: initial; /* Or any other color it was originally */
}


.subTotalDiv {
  width: 100%;
  padding: 1.2rem;
  border-bottom: 1px solid rgb(214, 214, 214);
}
.totalText {
  font-size: 2vw;
  font-weight: 500;
}
.viewCartDiv {
  width: 100%;
  margin-top: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.viewCartBtn {
  justify-content: flex-start;
  background-color: orange;
  color: white;
  width: 70%;
  height: 2.7rem;
}

.emptyCart {
  width: 100%;
  height: 60vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .sidePanel {
    z-index: 21;
    position: fixed;
    top: 0;
    right: 0;
    width: 60vw;
    height: 100vh;

    background-color: white;

    transform: translateX(100%);
    transition-duration: 750ms;

    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
      rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
      rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  }
  .sidePanelActive {
    transform: translateX(0%);
  }

  .itemDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    width: 100%;
    height: 100%;

    padding: 0.4rem;
    border-bottom: 1px solid rgb(185, 185, 185);
  }
  .itemInfo > h2 {
    font-size: 3.1vw;
  }
  .productName {
    color: grey;
    text-transform: uppercase;
  }
  .productPrice {
    font-weight: bold;
  }
  .productSize {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 6.3vw;
  }
  .selectQuantity {
    overflow: hidden;
    width: 55%;
    height: 1.7rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 0.4rem;
    border: 1px solid black;
  }
  .selectBtns {
    width: 2.5rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0 0.1rem;
  }
  .selectBtns > h2 {
    font-size: 3.4vw;
  }
  .subTotalDiv {
    padding: 1rem;
    border-bottom: 1px solid rgb(214, 214, 214);
  }
  .totalText {
    font-size: 3.8vw;
    font-weight: 500;
  }
}
