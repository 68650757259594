.carousel {
    margin-top: 5rem;
    border: 1px solid red;
    /* height: 300px */
}
/* .car-img {
    display: block;
    max-width: 100%;
    height: 300px;
} */
/* h3{
    border: 2px solid blue;
} */