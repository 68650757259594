.button-cart-buy {
  white-space: nowrap;
  min-height: 2rem;
  min-width: 4rem;

  margin-top: 0.2rem;
  font-weight: bold;
  border: 1.5px solid rgba(128, 128, 128, 0.318);
  border-radius: 6px;
  padding: 0.4em 2.8rem;
  color: white;
  background-color: black;

  margin: 0 1rem;
}

.button-cart-buy:hover {
  white-space: nowrap;
  min-height: 2rem;
  min-width: 4rem;

  font-weight: bold;
  border: 1.5px solid rgba(128, 128, 128, 0.318);
  border-radius: 6px;
  padding: 0.4em 2.8rem;
  color: black;
  background-color: white;

  margin: 0 1rem;
}
/* S M A L L */
@media only screen and (max-width: 600px) {
  .button-cart-buy {
    white-space: nowrap;
    min-height: 2rem;
    min-width: 2.8rem;

    margin-top: 0.2rem;
    font-weight: bold;
    border: 1.5px solid rgba(128, 128, 128, 0.318);
    border-radius: 6px;
    padding: 0.4em 2.8rem;
    color: white;
    background-color: black;

    margin: 0 0.2rem;
  }

  .button-cart-buy:hover {
    white-space: nowrap;
    min-height: 2rem;
    min-width: 2.8rem;

    font-weight: bold;
    border: 1.5px solid rgba(128, 128, 128, 0.318);
    border-radius: 6px;
    padding: 0.4em 2.8rem;
    color: black;
    background-color: white;

    margin: 0 0.2rem;
  }
}
