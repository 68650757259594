:root {
  /* 
    m == mobile,
    w == web
  */
  --mItemTextSize: 3vw;
  --wItemTextSize: 3.8vw;

  --secondary-color: #00ff00;
  --background-color: #000000;
}



* {
  margin: 0;
  padding: 00;
  box-sizing: border-box;
}
.video-container {
  z-index: 10;
  height: 100vh !important;
  width: 100vw;
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.15);
}
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #FCFCFF;
  z-index: 100;
}

.loader {
  width: 120px;
  height: 100px;
}


#reactPlayer {
  position: absolute;
  /* top: 50%;
  left: 50%; */
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  width: 100%;
  height: 100%;

  z-index: -10;
}

/* toastify: */
.toastProgress {
  background: #484333 !important;
}

.titleContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.title {
  white-space: nowrap;
  font-size: 6vw;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;

  letter-spacing: 0.3rem;
}

.btnContainer {
  display: block;

  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.landingBtns {
  background-color: black;
  color: white;
  border: black solid 2px;
  padding: 0.6em 3.1rem;
  margin: 0 2rem;

  text-transform: uppercase;
  font-weight: 800;
}
/* .landingBtns:hover {
  background-color: white;
  color: black;
  border: black solid 2px;
  padding: 0.6em 3.1rem;
  margin: 0 2rem;
  
  text-transform: uppercase;
  font-weight: 800;
} */

.mainBtns {
  font-size: 2vw;
}

.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.flex-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

/* R O D A L */
.rodalContainer {
  padding: 0;
  margin: 0;
}
.rodal-parent {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.name-label {
  padding: 0.5rem;
  /* border: 2px solid red; */
  text-align: center;
}
.name-label.flex-col {
  margin: 0.5rem;
}
.name-input {
  /* background-color: pink; */
  width: 100%;
}
.rodal-submit-button {
  border: 1px solid red;
  padding: 0.4rem 1rem;
  background-color: orange;
  margin: 1rem;
}
.formText {
  /* background-color: orange; */
  margin: 0.5rem;
}
.errorText {
  padding-top: 1rem;
  margin: auto;
  font-size: 1.1vw;
  color: red;
}

/* 
    MENU SECTION  
*/
.menuSection {
  width: 100vw;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: black;
}
.megaContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  display: block;
  padding: 2rem;

  /* border: 10px solid purple; */
}
.itemText {
  display: flex;

  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  /* border: black solid 1px; */

  text-align: center;

  width: 60%;
}
.itemText > * {
  flex-grow: 5;
}
.itemTitle {
  flex-grow: 2;
}
.itemTitle > h2 {
  text-transform: uppercase;
  font-family: "Oleo Script", cursive;
  font-family: "Roboto", sans-serif;

  font-weight: bold;
  font-size: var(--wItemTextSize);

  /* text-shadow: -2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.horizontalLine {
  /* margin: 0rem 0.5rem; */
  height: 0.3rem;
  width: 5rem;
  background-color: black;
}

.wrapper {
  width: 80%;
}
.itemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* background-color: pink; */
}

.itemImageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: relative;
}
.displayNone {
  opacity: 0;
}
.shopNowBanner {
  width: 100%;
  height: 30%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: black;
  z-index: 5;

  opacity: 1;
  transition: all 0.3s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;
}

.shopNowButton {
  background-color: white;

  width: 14rem;
  height: 2.3rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
}
.btntext {
  height: 100%;
  text-transform: uppercase;
  font-family: "Oleo Script", cursive;
  font-family: "Roboto", sans-serif;
  font-size: 1vw;

  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  width: 19.73vw;
  height: 29.07vh;
  margin: 1rem 1rem;
  position: relative;
}
.item img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

/*
  Friend n Family Section Banner
*/
.fnfSectionBanner {
  width: 100vw;
  min-height: 38vh;

  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
}
.fndTitle {
  text-transform: uppercase;
  font-size: 2.835vw;
}
.fnfButton {
  margin-top: 1rem;
  background-color: black;
  color: white;
  border: white solid 2px;

  text-transform: uppercase;
  font-size: 1.2vw;

  width: 15vw;
  height: 3vw;

  transition: all 0.5s ease-in-out;
}
.fnfButton:hover {
  background-color: white;
  color: black;
  border: white solid 0px;
}

/* 
  Footer 
*/

.footerHyperlink {
  color: rgb(178, 177, 177);
}
.footerHyperlink:hover {
  color: rgb(251, 251, 251);
}
.socialmediaIcons a:nth-child(2) {
  /* border: 1px solid orange; */
  transform: translateY(-1px);
}

.footerText {
  text-decoration: none;
  color: rgb(222, 222, 222);
  font-size: 1.7vw;
}
.footerSection {
  width: 100%;
  height: 16.2vh;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: rgb(128, 128, 128);

  padding: 1rem;
}
.socialmediaIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.socialmediaIcons > * {
  margin: 0.7rem;
}

/* L A R G E */
@media only screen and (min-width: 600px) {
  .landingBtns {
    background-color: black;
    color: white;
    border: black solid 2px;
    padding: 0.6em 3.1rem;
    margin: 0 2rem;

    text-transform: uppercase;
    font-weight: 800;
  }
  .landingBtns:hover {
    background-color: white;
    color: black;
    border: black solid 2px;
    padding: 0.6em 3.1rem;
    margin: 0 2rem;

    text-transform: uppercase;
    font-weight: 800;
  }
}

/* M E D I U M */
@media only screen and (min-width: 600px) and (max-width: 999px) {
  .landingBtns {
    background-color: black;
    color: white;
    padding: 1rem 1.5rem;
    margin: 1rem;

    text-transform: uppercase;
    border: black solid 2px;
  }
  /* .landingBtns:hover {
    background-color:  green;
    color: black;
    padding: 1rem 1.5rem;
    margin: 1rem;

    text-transform: uppercase;
    border: black solid 2px;
  } */
}

/* S M A L L */
@media only screen and (max-width: 600px) {
  .title {
    font-size: 4.8vw;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;

    letter-spacing: 0.1rem;

    white-space: nowrap;
    /* overflow: hidden;
    text-overflow: ellipsis;  */
  }
  .btnContainer {
    margin-top: 2rem;

    display: block;

    width: 100%;

    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
  }

  .landingBtns {
    background-color: black;
    color: white;
    padding: 0.5rem 0.6rem;
    margin: 0.5rem;

    text-transform: uppercase;
    border: black solid 2px;
  }
  /* .landingBtns:hover {

    background-color: white;
    color: black;
    padding: 1rem 1.5rem;
    margin: 1rem;

    text-transform: uppercase;
    border: black solid 2px;

  } */

  .btntext {
    height: 100%;
    text-transform: uppercase;
    font-family: "Oleo Script", cursive;
    font-family: "Roboto", sans-serif;
    font-size: 2.7vw;

    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item {
    width: 40vw;
    height: 30vh;
    margin: 1.3rem;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item img {
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .horizontalLine {
    height: 0.3rem;
    width: 3rem;
    background-color: black;
  }

  /* hiding extra images for smaller screens: */
  #Desiretofly-2,
  #Desiretofly-3,
  #Desiretofly-4 {
    display: none;
  }
  #Notforyou-1,
  #Notforyou-2,
  #Notforyou-3 {
    display: none;
  }
  .dtf-box,
  .nfy-box {
    width: 100%;
    margin: auto;
    object-fit: cover;
    object-position: center;
  }

  .fnfButton {
    margin-top: 1rem;
    padding: 0.7rem;
    background-color: black;
    color: white;
    border: white solid 2px;

    text-transform: uppercase;
    font-size: 3.8vw;

    width: 35vw;
    height: 12vw;

    transition: all 0.5s ease-in-out;
  }
  .fnfButton:hover {
    background-color: white;
    color: black;
    border: white solid 0px;
  }

  /* 
  Footer 
*/
  .footerSection {
    width: 100%;
    height: 16.2vh;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-color: grey;
  }
  .socialmediaIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .socialmediaIcons > * {
    margin: 0.7rem;
  }
  .footerText {
    margin-top: 1rem;
    font-size: 3vw;
  }

  .sectionContainer {
    width: 90%;
  }
  .fndTitle {
    text-transform: uppercase;
    font-size: 5.4vw;
  }
  .fnfButton {
    padding: 0.4rem;
    margin: 1rem 0rem;
    background-color: black;
    color: white;
    border: white solid 2px;

    text-transform: uppercase;
    font-size: 3.7vw;

    width: 40vw;
    height: 10vw;

    transition: all 0.5s ease-in-out;
  }
  .fnfButton:hover {
    background-color: white;
    color: black;
    border: white solid 0px;
  }
  .mainBtns {
    font-size: var(--mItemTextSize);
  }

  /* F&F R O D A L */
  .errorText {
    padding-top: 1rem;
    margin: auto;
    font-size: 3vw;
    color: red;
  }
}
