* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -ms-overflow-style: none; /* hides unwanted scrollbar */
}
::-webkit-scrollbar {
  /* hides unwanted scrollbar */
  display: none;
}
.checkoutSection {
  width: 100vw;
  padding: 5rem;
  /* background-color: green; */
}
.flexCol {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.wrapper {
  width: 60%;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.stripeElements {
  margin: 1.4rem;
  padding: 1.4rem 1rem;
  background-color: white;
  border: solid rgb(200, 200, 200) 1px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.stripeElements.flexCol > * {
  padding: 0.4rem;
  margin: 0.3rem;
}
.singleStripeElement {
  padding: 0.4rem;
  margin: 0.4rem;
  overflow: hidden;
  height: auto;
}
.elementTitle {
  padding: 1rem;
  margin: 1rem;
}
.emailElement {
  overflow: hidden;
}

.btn {
  border: 1px solid red;
  padding: 0.4rem 1rem;
  background-color: orange;
  margin: 1rem;
}
.biggerText > h2{
  font-weight: 650;
  font-size: 1.8vw;
}

.stripeSection {
  width: 100vw;
  min-height: 100vh;

  background-color: white;
}

.col.finalCbox {
  margin: auto;
  padding: 1rem;

  /* background-color: pink; */
  border: 1px solid black;
}

.subHeading {
  padding: 0.5rem;
  color: grey;
}

/* M E D I U M */
@media only screen and (min-width: 600px) and (max-width: 999px) {
  .wrapper {
    width: 80%;
    margin: auto;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* S M A L L */
@media only screen and (max-width: 600px) {
  .wrapper {
    width: 100%;
    margin: auto;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .checkoutSection {
    width: 100vw;
    padding: 1rem;
    margin-bottom: 8rem;
    /* background-color: green; */
  }
  .biggerText > h2{
    font-weight: 700;
    font-size: 4vw;
  }
  .row.biggerText{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .btn {
    border: 1px solid red;
    padding: 0.4rem 1rem;
    background-color: orange;
    margin: 1rem;
  }
  
}