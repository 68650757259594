/* VIEW CART */
.body-container-vc {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
  gap: 30px;
  /* padding-top: 5rem; */
}
.cartItemsContainer-vc {
  width: 500px;
}

#viewCart{
  /* background-color: orange; */
  padding: 1rem;
  margin: 1rem 0rem;
}

@media (max-width: 1000px) {
  .body-container-vc {
    flex-direction: column;
    align-items: center; /* Optional: Center align items vertically */
  }
  .cartItemsContainer-vc {
    width: 350px;
    padding-bottom: 2rem;
  }
}

.itemDisplay-vc {
  display: flex;
  justify-content: center;
  flex-direction: row;

  width: 100%;
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgb(185, 185, 185);
}
.itemImg-vc {
  width: 30%;
  max-height: 50%;
}
.itemImg-vc > img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.itemInfo-vc {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  height: 100%;
  padding: 1rem;
}
.itemInfo-vc > h2 {
  font-size: 18px;
}
.productName-vc {
  color: grey;
  text-transform: uppercase;
}

.productSize-vc {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 0.8vw;
}
.selectQuantity-vc {
  overflow: hidden;
  width: 55%;
  height: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  border: 1px solid black;
}

.selectBtns-vc {
  width: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 0 0.2rem;
}
.selectBtns-vc > h2 {
  font-size: 16px;
}

/* ORDER SUMMARY */
.price-list-vc {
  width: 350px;
}

.subtotal-value-vc,
.delivery-value-vc,
.total-value-vc {
  display: flex;
  justify-content: space-between;
}
.h4-vc {
  display: inline-block;
}

.checkout-btn-vc {
  margin-top: 1rem;
  border: 1.5px solid rgba(128, 128, 128, 0.318);
  border-radius: 4px;
  padding-left: 3.6rem;
  padding-right: 3.6rem;
  width: 100%;
  color: white;
  background-color: green;
}
