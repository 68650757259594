.video-container-small {
  height: 65vh !important;
  width: 100vw;
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.15);
}

.titleContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.title {
  z-index: 5;
  white-space: nowrap;
  font-size: 6vw;
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;

  letter-spacing: 0.3rem;
}

.mainBtns {
  font-size: 2vw;
}
.shop-now-btn {
  z-index: 5;
  position: relative;
  left: 50%;
  bottom: 8%;
  transform: translateX(-50%);
}


/* P R O D U C T */
.displayProductContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center  */
}
.newImg{
  position: absolute; /* Make the image absolutely positioned */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the container without skewing */
  object-position: center; /* Center the image */
  z-index: -10;
}

.product-title {
  display: inline-block;
  padding-top: 8rem;
}

.product-title > h1 {
  text-transform: uppercase;
  font-family: "Oleo Script", cursive;
  font-family: "Roboto", sans-serif;

  font-weight: bold;
  font-size: var(--wItemTextSize);
}

.product-container {
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
}

.sideImageProduct-1-nfy,
.sideImageProduct-2-nfy {
  flex: 1;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  max-width: 300px;
  max-height: 600px;
}

.sideImageProduct-1,
.sideImageProduct-2 {
  flex: 1;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  max-width: 300px;
  max-height: 600px;
}

/* TODO: Fix main image ratio as 1:2:1 while screen is minimum */
.mainImageProduct {
  flex: 2;
  max-width: 400px;
  max-height: 1000px;
  border: 1px solid black;
}
.mainImageProduct-img {
  transition: transform 0.3s ease-in-out;
  position: relative;
}
.mainImageProduct-img:hover {
  transform: scale(1.05);
}
.quick-view {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: center;
}
.mainImageProduct-img:hover .quick-view {
  opacity: 1;
}

.sideImage {
  width: 100%;
  height: 95%;
  padding-top: 4rem;
  padding-bottom: 8rem;
  object-fit: fit;
}

.sideImage-nfy {
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  padding-bottom: 12rem;
  object-fit: fit;
}

.mainImage {
  width: 100%;
  max-height: 100%;
  display: block;
  transition: filter 0.2s ease;
}
.product-price-container {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* C A R O U S E L */

.carousel-slide {
  position: relative;
}

.carousel-container {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.carousel-image {
  padding: 0.1rem;
  width: 100%;
  transition: transform 0.3s;
}

@media only screen and (max-width: 1300px) {
  /* CSS rules to apply when screen width is 1200 pixels or less */
  .carousel-image {
    width: 100%;
  }
}

.rodal-close {
  &::before,
  &::after {
    /* background-color: black; */
    /* padding: 20px; */
    width: 110%;
    height: 20%;
    overflow: visible;
  }
}

.arrow-right, .arrow-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 8rem;
}

@media only screen and (max-width: 700px) {
    /* CSS rules to apply when screen width is 1200 pixels or less */
    .carousel-container {
      width: 100%;
    }
    .carousel-image {
      width: 100%;
      height: 100%;
    }
    .enlarged-image {
      width: 100%;
      /* height: 340px; */
    }
}

.parent-div {
  position: relative;
}

.parent-parent.blur {
  filter: blur(4px); /* Adjust the blur amount as needed */
}

.rodal-parent {
  z-index: 9999; /* Ensure the modal is above the blurred background */
}
